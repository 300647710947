import React, {  useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import { toast } from 'react-toastify';
import { VISIT_EXECUTIVE_ROLES } from '../../config/constant';
import { MasterService } from '../../services';
import { Calendra3} from './DatePicker'
import { sort } from '../../config/helper';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
const { ValueContainer, Placeholder } = components;
const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>
                {props.selectProps.placeholder}
            </Placeholder>
            {React.Children.map(children, child =>
                child && child.type !== Placeholder ? child : null
            )}
        </ValueContainer>
    );
};


const BMTargetTopFilter = (props) => {
    const [roleList, setRoleList] = useState([])
    const [roleSelected, setRole] = useState(null);
    const [roleNameList, setRoleNameList] = useState(null)
    const [roleName, setRoleName] = useState(null);
    const [executiveData, setExecutiveData] = useState(null);
    const [executive, setExecutive] = useState(null);
    const [allUserIds, setAllUserIds] = useState([]);
    const [selectedMonthYear, setSelectedMonthYear] = useState(null);
    useEffect(()=>{
        getExecutiveList();
        getDirectExecutiveList();
    },[roleName])

    useEffect(() => {
        getRoleList()
    }, [])

    useEffect(() => {
        if (props.isReset) {
            handleReset()
        }
    }, [props.isReset])

    useEffect(() => {
            getRoleName()
    }, [roleSelected])

    const getExecutiveList = async () => {
        let searchData = {
            role_id: [roleSelected ? roleSelected.id : 1],
            user_id: [roleName ? roleName.id: 1]
        }
        MasterService.post('/sfa/dealer/executiveList', searchData)
            .then(function (response) {
                if (response.data.status === 200) {
                    let executive_list = response.data.data.executive_data.map(elm => ({ id: elm.id, label: elm.name, value:elm.name,user_id:elm.id,roleId:elm.roleId }));
                    setExecutiveData(executive_list);
                    // setAllUserIds(executive_list.map(elm => elm.user_id));
                } else {
                    toast.error(response.data.message);
                }
            })
            .catch(function (response) {
            });
    }

    const getDirectExecutiveList = async () => {
        let searchData = {
            role_id: [roleSelected ? roleSelected.id : 1],
            user_id: [roleName ? roleName.id: 1]
        }
        MasterService.post('/sfa/dealer/directExecutiveList', searchData)
            .then(function (response) {
                if (response.data.status === 200) {
                    let executive_list = response.data.data.executive_data.map(elm => ({ id: elm.id, label: elm.name, value:elm.name,user_id:elm.id,roleId:elm.roleId }));
                    setAllUserIds(executive_list.map(elm => elm.user_id));
                } else {
                    toast.error(response.data.message);
                }
            })
            .catch(function (response) {
            });
    }

    const getRoleList = async () => {
        MasterService.post('/sfa/user/role_list', {})
            .then(function (response) {
                if (response.data.status === 200) {
                        let role_list = response.data.data.filter(e => VISIT_EXECUTIVE_ROLES.includes(e.id))
                        role_list = role_list.map(elm => ({ id: elm.id, label: elm.name, value: elm.name }));
                        setRoleList(role_list)
                }
            })
            .catch(function (response) {
            });
    }

    const handleChange = (event, name) => {
        if (name === "role") {
            setRole(event)
            setRoleName(null)
            setExecutive(null)
        }
        if (name === "roleName") {
            setRoleName(event)
            setExecutive(null)
        }
        if (name === 'executive') {
            setExecutive(event)
        }
        if (name === 'month') {
            setSelectedMonthYear(event);
        }
    };

    const getRoleName = async () => {
        let searchData = {
            search_text: '',
            role_id: roleSelected ? roleSelected.id : '',
        }
        await MasterService.post('/sfa/dealer/executiveListByCities', searchData)
            .then(function (response) {
                if (response.data.status === 200) {
                    let executive_names = response.data.data.executive_data.map((e) => ({
                        label: e.name,
                        id: e.id,
                        value: e.id,
                        user_id: e.id
                    }))
                    executive_names = sort(executive_names, 'label')
                    setRoleNameList(executive_names);
                } else {
                    if (response.data.error && response.data.error.length > 0) {
                        response.data.error.forEach(element => {
                            toast.error(element);
                        });
                    } else {
                        toast.error(response.data.message);
                    }
                }
            })
            .catch(function (response) {
            });
    }

    const formatDate = (date) => {
        if (!date) return '';
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); 
        return `${year}-${month}-01`;
    };
    const handleSearch = (is_reset) => {
            if (is_reset) {
                let params = {
                    target_month: new Date(),
                    userId: null
                }
                props.activeRmList(params)
            } else {
                if (roleSelected && !roleName) {
                    toast.error("Please select Role List");
                    return;  
                }
                let formattedDate = formatDate(selectedMonthYear);
                let userId = null
                if (roleName && roleName.id) {
                    userId = allUserIds
                }
                if (executive && executive.id) {
                    userId = [executive.id];
                }
                let params = {
                    target_month: formattedDate,
                    userId: userId
                }
                props.activeRmList(params)
            }
    }

    const handleReset = () => {
        setSelectedMonthYear(null)
        setRole(null)
        setRoleName(null)
        setExecutive(null)
        setExecutiveData(null)
        handleSearch(true)
    }

    return (
        <React.Fragment>
            <div className="visit-report-filter">
                <fieldset className="rangepicker-calendra">
                    <div className="material">
                    <Calendra3
                            placeholder={"Select Month"}
                            defaultDate={selectedMonthYear}
                            handleDateChange={(date) => handleChange(date, 'month')}
                        />
                    </div>
                </fieldset>
                    <fieldset className="single-select">
                        <div className="material">
                            <Select components={{ ValueContainer: CustomValueContainer }}
                                value={roleSelected}
                                onChange={(e) => handleChange(e, 'role')}
                                options={roleList}
                                placeholder="Select Role"
                                className="react-select"
                                classNamePrefix="react-select"
                            />
                        </div>
                    </fieldset>
                    <fieldset className="single-select">
                        <div className="material">
                            <Select
                                components={{ ValueContainer: CustomValueContainer }}
                                value={roleName}
                                isDisabled={!roleSelected}
                                onChange={(e) => handleChange(e, 'roleName')}
                                options={roleNameList}
                                placeholder="Role List"
                                className="react-select"
                                classNamePrefix="react-select"
                        />
                    </div>
                </fieldset>
                 <fieldset className="single-select">
                    <div className="material">
                                 <Select components={{ ValueContainer: CustomValueContainer }}
                                    value={executive}
                                    isDisabled={!roleName}
                                    onChange={(e) => handleChange(e, 'executive')}
                                    options={executiveData}
                                    placeholder="Executive List"
                                    className="react-select"
                                    classNamePrefix="react-select"
                                />
                    </div>
                </fieldset>
                <div className="btn-submit-reset">
                    <button type="submit" className="btn-primary" onClick={() => { handleSearch(false) }}>Search</button>
                    <button className="btn-reset" onClick={() => handleReset()}>Reset</button>
                </div>
            </div>
        </React.Fragment>
    )
}
export default BMTargetTopFilter;