import React, { useEffect, useState } from "react";
import { Tab } from 'react-bootstrap';
import BMTargetTopFilter from "./BMTargetTopFilter"
import { MasterService } from "../../services";
import Loader from "../common/loader";
import BMTargetForm from './BMTargetForm';
import { getFirstDate } from '../../config/helper';

const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

const BMTarget = (props) => {
    const [loading, setLoading] = useState(false);
    const [isReset, setReset]   = useState(0);
    const [userListData, setuserListData] = useState([]);
    const [targetMonth, setTargetMonth] = useState("");

    useEffect(() => {
        getActiveRmList();
    }, []);
    const getActiveRmList = async (params = {}) => {
        let result = []
        let searchParams = {
            "role_id": [1,2,3,4,5,6,7,8],
            "user_id":params.userId
        }
        setLoading(true);
        setTargetMonth(params.target_month);
        await MasterService.post('/sfa/user/get-users', searchParams).then(function (response) {
            if (response && response.data && response.data.status === 200 &&  response.data.data && response.data.data.user_list) {
                result = response.data.data.user_list;
                result = result.map(({id,name,email }) => ({id,name,email }));
                getUserTarget(result,params);
            }else{
                setuserListData([]);
                setLoading(false)
            }
            setLoading(false);
        }).catch(function (response) {
            setLoading(false)
        });
        return result;
    }

    const getUserTarget = async (userList,params)=>{
        let result = []
        setLoading(true);
        let searchParams = {
            target_month:params.target_month
        }
        await MasterService.post('/sfa/user/get_target',searchParams).then(function (response) {
            if (response && response.data && response.data.status === 200 &&  response.data.data) {
                result = response.data.data;
                setLoading(false);
                if(result.length > 0){
                    for(let count = 0; count < userList.length; count ++){
                        let userData = userList[count];
                        let userExits = result.find(element => element.user_id == userData.id);
                        if(userExits){
                            userList[count].target_month = userExits.target_month;
                            userList[count].disb_target = userExits.disbursed_target;
                            userList[count].onboard_target = userExits.partner_taraget;
                            userList[count].meeting_target = userExits.meeting_target;
                            userList[count].loging_target = userExits.loging_target;
                            userList[count].disbursal_no_target = userExits.disbursal_no_target;
                            userList[count].loging_amount_target = userExits.loging_amount_target;
                            userList[count].active_partner_target = userExits.active_partner_target;
                            
                        }else{
                            userList[count].target_month = ""
                            userList[count].disb_target = "";
                            userList[count].onboard_target = "";
                            userList[count].meeting_target = "";
                            userList[count].loging_target = "";
                            userList[count].disbursal_no_target = "";
                            userList[count].loging_amount_target = "";
                            userList[count].loging_amount_target = "";
                        }
                    }
                    setuserListData(userList); 
                    return result;
                }else{
                    setuserListData(userList);
                }
            }else{
                setuserListData(userList);
            }
        }).catch(function (response) {
            setLoading(false)
        });
    }

    return (
        <React.Fragment>
            {loading ? <Loader /> : null}
            <div className="container-fluid">
                <div className="dealer-user-mapping-user">
                    <div className="top-heading d-flex">
                        <h1>Yoddha Target</h1>
                    </div>
                    <div className='visit-filter-outer'>
                        <BMTargetTopFilter isReset={isReset}  activeRmList={getActiveRmList} />
                    </div>
                    <div className="dealer-user-mapping-tab-outer no-visit-report-outer">
                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    <BMTargetForm
                                        loading={loading}
                                        userListData = {userListData}
                                        target_month={months[new Date(targetMonth).getMonth()] || months[new Date().getMonth()]}
                                        targetMonth = {targetMonth}
                                    />
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default BMTarget;