import React, { useEffect, useState, useRef } from 'react';
import Select, { components } from 'react-select';
import InputField from '../element/Input';
import { MasterService } from "../../services"; 
import Loader from "../common/loader";
import { toast } from 'react-toastify';
import csvToJsonLib from "csvtojson";
import Modal from '../common/Modal';

const { ValueContainer, Placeholder } = components;

const BMTargetForm = (props) => {
    const [userListData, setuserListData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isFormEditable, setIsFormEditable] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const fileInputRef = useRef(null);
    const fileNameRef = useRef(null);

    useEffect(() => {
        setuserListData(props.userListData);
        validateTargetMonth()
    }, [props.userListData]);
    
    const convertCsvToJson = async (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = async (event) => {
            const csvData = event.target.result;
            const jsonArray = await csvToJsonLib().fromString(csvData);
            resolve(jsonArray);
          };
          reader.onerror = (error) => reject(error);
          reader.readAsText(file);
        });
      };

    const convertJsonToCsv = (json) => {
        const headers = [...new Set(json.flatMap(item => Object.keys(item)))];
        const items = json.map(item => headers.map(header => item[header] || ""));
        const csv_Data = [headers.join(","), ...items.map(row => row.join(","))].join("\n");
        return csv_Data;
    }; 
      const downloadFile = (fileData, fileType, fileName) => {
        const blob = new Blob([fileData], { type: fileType });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      };


    const CustomValueContainer = ({ children, ...props }) => {
        return (
            <ValueContainer {...props}>
                <Placeholder {...props} isFocused={props.isFocused}>
                    {props.selectProps.placeholder}
                </Placeholder>
                {React.Children.map(children, child =>
                    child && child.type !== Placeholder ? child : null
                )}
            </ValueContainer>
        );
    };
    const downloadData = async () => {
        const cleanedData = userListData.map(obj => {
            return Object.keys(obj)
              .filter(key => !key.includes('_error'))
              .reduce((acc, key) => {
                acc[key] = obj[key];
                return acc;
              }, {});
          });
        if (cleanedData.length > 0) {
            const csvData = convertJsonToCsv(cleanedData);
            downloadFile(csvData, "text/csv", `bm-target-list-${Math.round(+new Date() / 1000)}.csv`);
        } else {
            toast.error("No data available to export");
        }
    };

    const handleFileChange = async (event) => {
        const { files } = event.target;
        if (files.length > 0) {
            const fileName = files[0].name;
            const jsonArray = await convertCsvToJson(files[0]);
            if (jsonArray.length > 0) {
                setuserListData(jsonArray);
            } else {
                toast.error("No valid data available in the uploaded file.");
            }
        } else {
            if (fileNameRef.current) {
                fileNameRef.current.textContent = "";
            }
        }
    };
    
    


    const handleInputChange = async (event) => { 
        let current_index = (event.target.getAttribute('current_index')-1);
        let user_id       = +event.target.getAttribute('user_id');
        let current_tab   = event.target.getAttribute('current_tab');
        let selectedValue = event.target.value;
        let userList      = [...userListData];
        if(selectedValue && selectedValue > 0){
            if(userList[current_index][current_tab+'_error']){
                delete userList[current_index][current_tab+'_error'];
            }
        }else{
            if(current_tab == 'disb_target'){
                userList[current_index][current_tab+'_error'] = "Amount should be >= 100,00,000"
            }else if(current_tab == 'onboard_target'){
                userList[current_index][current_tab+'_error'] = "Onboard Target should be >= 1"
            }
            else if (current_tab == 'meeting_target'){
                userList[current_index][current_tab+'_error'] = "Meetng Target should be >= 1"
            }
            else if (current_tab == 'loging_target'){
                userList[current_index][current_tab+'_error'] = "Log In Target should be >= 1"
            }
            else if (current_tab == 'disbursal_no_target'){
                userList[current_index][current_tab+'_error'] = " Disbursal No Target should be >= 1"
            }
            else if (current_tab == 'loging_amount_target'){
                userList[current_index][current_tab+'_error'] = " Amount should be >= 100,00,000"
            }
            else if (current_tab == 'active_partner_target'){
                userList[current_index][current_tab+'_error'] = " Partner Target should be >= 1"
            }
        }
        userList[current_index][current_tab] = selectedValue;
        setuserListData(userList);
    };

    const handleClickSumbit = async (event) => {
        setLoading(true);
        if(validateForm()){
            let userDataClone = [...userListData];
            if (props?.targetMonth) {
                for (let i = 0; i < userDataClone.length; i++) {
                    userDataClone[i].target_month = props.targetMonth;
                }
            }
            await MasterService.post('/sfa/user/set_target', {"userData": JSON.stringify(userDataClone)}).then(function (response) {
                if(response.data.status == 200){
                    toast.success(response.data.message);
                }else{
                    toast.error(response.data.message);
                }
                setLoading(false);
            }).catch(function (response) {
                setLoading(false)
            });
        }else{
            setLoading(false);
            console.log("Some error in Form");
        }
        
    }

    const validateForm = ()=>{
        let userList      = [...userListData];
        let submitForm    = true;
        for(let count = 0; count < userList.length; count ++){
            if(!userList[count].disb_target || userList[count].disb_target < 10000000){
                userList[count].disb_target_error = "Amount should be >= 100,00,000";
                submitForm = false;
            }else{
                delete userList[count].disb_target_error;
                // submitForm = true;
            }
            if(!userList[count].onboard_target || userList[count].onboard_target < 1){
                userList[count].onboard_target_error = "Onboard target should be >= 1";
                submitForm = false;
            }else{
                delete userList[count].onboard_target_error;
                // submitForm = true;
            }
            if(!userList[count].meeting_target || userList[count].meeting_target < 1){
                userList[count].meeting_target_error = "Meeting Target should be >= 1 ";
                submitForm = false;
            }else{
                delete userList[count].meeting_target_error;
            }
            if(!userList[count].loging_target || userList[count].loging_target < 1){
                userList[count].loging_target_error = "Log In Target should be >= 1 ";
                submitForm = false;
            }else{
                delete userList[count].loging_target_error;
            }
            if(!userList[count].disbursal_no_target || userList[count].disbursal_no_target < 1){
                userList[count].disbursal_no_target_error = "Disbursal No Target should be >= 1 ";
                submitForm = false;
            }else{
                delete userList[count].disbursal_no_target_error;
            }
            if(!userList[count].loging_amount_target || userList[count].loging_amount_target < 1){
                userList[count].loging_amount_target_error = "Amount should be >= 100,00,000";
                submitForm = false;
            }else{
                delete userList[count].loging_amount_target_error;
            }
            if(!userList[count].active_partner_target || userList[count].active_partner_target < 1){
                userList[count].active_partner_target_error = "Partner Target should be >= 1";
                submitForm = false;
            }else{
                delete userList[count].active_partner_target_error;
            }
        }
        setuserListData(userList);
        return submitForm;
    }
    const validateTargetMonth = () => {
        const date = new Date();
        const currentMonth = date.toLocaleString('default', { month: 'long' });
        const nextMonth = new Date(date.getFullYear(), date.getMonth() + 1, 1).toLocaleString('default', { month: 'long' });
        const targetMonth = props.target_month;

        if (targetMonth === currentMonth || targetMonth === nextMonth) {
            setIsFormEditable(true);
        } else {
            setIsFormEditable(false);
        }
    };
    const openModal = () => {
        setShowPopup(true);
    }

    const closeModal = () => {
        setShowPopup(false);
    }

    return (
        <React.Fragment>
            {loading ? <Loader /> : null}
            <div className="top-heading d-flex">
                <h2>Yoddha Target {props.target_month}</h2>
                <button className="btn-line-b" onClick={() => openModal()}>
                    <i className="ic-export m-xs-r"></i>
                    <span>Upload Data</span>
                </button>
                <button className="btn-line">
                        <i className="ic-export m-xs-r"></i>
                        <span onClick={() => downloadData()} >Export Data</span>
                    </button>
            </div>
            <div className='visit-report-table-heading'>
                <div className='visit-report-table data-table bg-white radius'>
                <table>
                    <thead>
                        <tr >
                        <th className='yoddha-id'>Yoddha ID</th>
                            <th>Yoddha Name</th>
                            <th>Disb. Target 
                            <a className='tooltip'>
                            <i className='ic-info-icon1 m-sm-l'></i>
                            <span className="tooltiptext">1 Cr. {'<'} Disb. target {'<'} 5K Cr.</span>
                            </a>
                            </th >
                            <th>Onboard Target
                            <a className='tooltip' >
                            <i className='ic-info-icon1 m-sm-l'></i>
                            <span className="tooltiptext">1 {'<'}= Onboard target {'<'} 500</span>
                            </a>
                            </th>
                            <th>Number of Meeting
                            <a className='tooltip' >
                            <i className="ic-info-icon1 m-sm-l"></i>
                            <span className="tooltiptext">1 {'<'}= No of Meeting target {'<'} 5k</span>
                            </a>
                            </th>
                            <th>Number of Log In
                            <a className='tooltip' >
                            <i className="ic-info-icon1 m-sm-l"></i>
                            <span className="tooltiptext">1 {'<'}= No of Log In target {'<'} 15k</span>
                            </a>
                            </th>
                            <th>Number of Disbursal
                            <a className='tooltip' >
                            <i className="ic-info-icon1 m-sm-l"></i>
                            <span className="tooltiptext">1 {'<'}= No of Disbursal target {'<'} 10k</span>
                            </a>
                            </th>
                            <th>Amount of Log In
                            <a className='tooltip' >
                            <i className="ic-info-icon1 m-sm-l"></i>
                            <span className="tooltiptext">1 Cr. {'<'} Log In Amount target {'<'} 10K Cr.</span>
                            </a>
                            </th>
                            <th>Number of Partner
                            <a className='tooltip' >
                            <i className="ic-info-icon1 m-sm-l"></i>
                            <span className="tooltiptext">1 {'<'}= No of partner target {'<'} 750</span>
                            </a>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {userListData?.length > 0 && userListData.map((element, index) => (
                            <tr key={index}>
                                <td>{element.id}</td>
                                <td>{element.name}</td>
                                <td>
                                    <fieldset className="material">
                                            <InputField type="number" error={userListData[index].disb_target_error} name="disb_target"
                                            maxLength={13}
                                            label="Disb. Target" placeholder=" " value={userListData[index].disb_target || 0} disabled={!isFormEditable}  user_id={element.id} current_index={index+1} current_tab="disb_target" required={true} onChange={handleInputChange} />
                                            <label data-label="Disb. Target*" required={true} className="form-label"></label>
                                    </fieldset>
                                </td>
                                <td>
                                    <fieldset className="material">
                                            <InputField type="number" error={userListData[index].onboard_target_error} label="Onboard Target" placeholder=" " name="onboard_target" 
                                            maxLength={5}
                                            disabled={!isFormEditable}
                                            value={userListData[index].onboard_target || 0} user_id={element.id} current_index={index+1} current_tab="onboard_target"  onChange={handleInputChange} />
                                            <label data-label="Onboard Target*" className="form-label"></label>

                                    </fieldset>
                                </td>
                                <td>
                                    <fieldset className="material">
                                            <InputField type="number" error={userListData[index].   meeting_target_error} label="Meeting Target" placeholder=" " name="meeting_target" maxLength={5} disabled={!isFormEditable}
                                             value={userListData[index].meeting_target || 0} user_id={element.id} current_index={index+1} current_tab="meeting_target"  onChange={handleInputChange} />
                                            <label data-label="No. of Meeting*" className="form-label"></label>
                                    </fieldset>
                                </td>
                                <td>
                                    <fieldset className="material">
                                            <InputField type="number" error={userListData[index].loging_target_error} label="Loging Target" placeholder=" " name="loging_target" maxLength={5} value={userListData[index].loging_target || 0} disabled={!isFormEditable} user_id={element.id} current_index={index+1} current_tab="loging_target"  onChange={handleInputChange} />
                                            <label data-label="No. of Log In*" className="form-label"></label>
                                    </fieldset>
                                </td>
                                <td>
                                    <fieldset className="material">
                                            <InputField type="number" error={userListData[index].disbursal_no_target_error} label="Disbursal No Target" placeholder=" " name="disbursal_no_target" 
                                            maxLength={5}
                                            disabled={!isFormEditable}
                                            value={userListData[index].disbursal_no_target || 0} user_id={element.id} current_index={index+1} current_tab="disbursal_no_target"  onChange={handleInputChange} />
                                            <label data-label="No. of Disbursal*" className="form-label"></label>

                                    </fieldset>
                                </td>
                                <td>
                                    <fieldset className="material">
                                            <InputField type="number" error={userListData[index].loging_amount_target_error} label="Loging Amount Target" placeholder=" " maxLength={13}name="loging_amount_target" value={userListData[index].loging_amount_target || 0} disabled={!isFormEditable} user_id={element.id} current_index={index+1} current_tab="loging_amount_target"  onChange={handleInputChange} />
                                            <label data-label="Amt. of Log In*" className="form-label"></label>
                                    </fieldset>
                                </td>
                                <td>
                                    <fieldset className="material">
                                            <InputField type="number" error={userListData[index].active_partner_target_error} label="Active Partner Target" placeholder=" " name="active_partner_target" 
                                             maxLength={5}
                                             disabled={!isFormEditable}
                                            value={userListData[index].active_partner_target || 0} user_id={element.id} current_index={index+1} current_tab="active_partner_target"  onChange={handleInputChange} />
                                            <label data-label="No. of Partner*" className="form-label"></label>
                                    </fieldset>
                                </td>
                            </tr>
                        ))}      
                    </tbody>
                </table> 
                </div>
                {isFormEditable && (
                    <div style={{display:"flex", justifyContent:"end", margin:'20px 0px', paddingBottom:20}}>
                        <button className='btn-primary' onClick={handleClickSumbit}>
                            Save
                        </button>
                    </div>
                )}
                {showPopup && (
                    <Modal show={true} handleClose={closeModal}>
                        <div className="popup-content-b">
                            <div className="upload-popup-b">
                                <h3>Upload CSV</h3>
                                <input
                                    type="file"
                                    accept=".csv"
                                    ref={fileInputRef}
                                />
                            </div>
                            <div className="popup-buttons-b">
                                <a 
                                    href="/bm-target-sample.csv" 
                                    download="bm-target-sample.csv" 
                                >
                                Download CSV
                                </a>
                                <button className="cancel-btn-b" onClick={closeModal}>Cancel</button>
                                 <button className="submit-btn-b" onClick={() => {
                                const file = fileInputRef.current.files[0];
                                if (file) {
                                 if (file.type === "csv" || file.name.endsWith('.csv')) {
                                     handleFileChange({ target: { files: fileInputRef.current.files } });
                                        closeModal();
                                    } else {
                                        toast.error("Please upload a valid CSV file.");
                                    }
                                } else {
                                    toast.error("Please select a file.");
                                }
                            }}>Upload</button>
                            </div>
                        </div>
                    </Modal>
                )}
            </div>
        </React.Fragment>
    )
}

export default BMTargetForm;